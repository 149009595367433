<!--
 * @Author: your name
 * @Date: 2021-06-30 10:10:14
 * @LastEditTime: 2021-07-31 10:10:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/home/passwd.vue
-->
<template>
  <v-form ref="form">
    <inc-header
      id="menu"
      :project="{ name: '监护人信息' }"
      :mobile="mobile"
      :menu="menu"
      :help="false"
    ></inc-header>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4" offset-md="4">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-card app>
              <v-system-bar color="indigo lighten-2" dark> </v-system-bar>
              <v-app-bar elevation="1">
                <v-icon class="mr-2 text-h4">mdi-key</v-icon>
              </v-app-bar>
              <v-divider></v-divider>
              <v-card-text class="py-10 px-7">
                <validation-provider
                  name="旧密码"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="旧密码"
                    counter
                    @click:append="show1 = !show1"
                    :error-messages="errors"
                    outlined
                  >
                  </v-text-field>
                </validation-provider>
                <validation-provider
                  name="新密码"
                  v-slot="{ errors }"
                  rules="required|min:6|max:18|password|confirmed:password2"
                >
                  <v-text-field
                    v-model="password1"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-2"
                    label="新密码"
                    class="input-group--focused"
                    counter
                    outlined
                    @input="checkPassword"
                    @click:append="show2 = !show2"
                    :error-messages="errors"
                  >
                  </v-text-field>
                </validation-provider>
                <validation-provider
                  name="确认新密码"
                  v-slot="{ errors }"
                  rules="required|min:6|max:18|password"
                  vid="password2"
                >
                  <v-text-field
                    v-model="password2"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    label="确认新密码"
                    :error-messages="errors"
                    class="input-group--focused"
                    counter
                    @click:append="show3 = !show3"
                    outlined
                  >
                  </v-text-field>
                </validation-provider>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text align="right">
                <v-btn color="success" :disabled="invalid" @click="postPasswd">
                  <v-icon class="mr-2">mdi-check</v-icon>
                  确认修改
                </v-btn>
              </v-card-text>
            </v-card>
          </validation-observer>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbarBackground">
        {{ errmsg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="rewindow">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="dialog" persistent max-width="400">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>
              <v-icon>mdi-alert-circle-outline</v-icon>
              系统提示
            </v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-6" align="center">密码修改成功!</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="success" @click="rewindow">
                <v-icon class="pr-2">mdi-check</v-icon>
                确定
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-container>
  </v-form>
</template>
<script>
import incHeader from "../inc-header.vue";
export default {
  components: { incHeader },
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      menu: [],
      show1: false,
      show2: false,
      show3: false,
      password: "",
      password1: "",
      password2: "",
      errPass: "",
      disabled: true,
      rules: [
        (v) => !!v || "不能为空",
        (v) => (v && v.length >= 6) || "密码不能少于6位字符",
      ],
      snackbar: false,
      snackbarBackground: "red",
      errmsg: "",
      dialog: false,
    };
  },
  methods: {
    checkPassword() {
      if (this.password == null) return false;
      if (
        this.password.length >= 6 &&
        this.password1.length >= 6 &&
        this.password1 === this.password
      ) {
        this.errPass = "新密码与旧密码不能相同";
      } else if (this.password1.length >= 6 && this.password2.length >= 6) {
        if (this.password1 !== this.password2) {
          this.errPass = "新密码与确认密码必须相同";
        } else {
          this.errPass = "";
        }
      } else {
        this.errPass = "";
      }
    },
    postPasswd() {
      if (this.$refs.form.validate() == false) return false;
      let data = {
        module: "usinfo",
        title: "repasswd",
        data: {
          passwd_old: this.password,
          passwd: this.password1,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        ////console.log(res)
        if (res.data.usinfo.errcode == 0) {
          //that.password = that.password1 = that.password2 = "";
          //that.$refs.form.reset()
          that.dialog = true;
        } else {
          that.snackbar = true;
          that.snackbarBackground = "red";
          that.errmsg = res.data.usinfo.errmsg;
        }
      });
    },
    ckpass() {
      if (this.password === null) return false;
      if (
        this.password.length >= 6 &&
        this.password1.length >= 6 &&
        this.password1 === this.password2
      ) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    rewindow() {
      //this.$router.go(0)
      //window.location.reload();

      //this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.dialog = false;
      return false;
    },
  },
  watch: {
    password() {
      this.ckpass();
    },
    password1() {
      this.ckpass();
    },
    password2() {
      this.ckpass();
    },
  },
  created() {
    //this.$parent.$parent.$parent.selectedItem = 3;
  },
};
</script>